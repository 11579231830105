import React from "react";

function FormulaNote() {
  return (
    <div>
      <p>
        Year Columns: The number of students with a specific graduation year
        assigned to a counselor. The "Other YOG" column shows the number of
        students with a graduation year different from the others.{" "}
      </p>
      <p>
        Program Columns: The number of students enrolled in Select, Premier, and
        Elite programs assigned to a counselor.{" "}
      </p>
      <p>
        "Suspended" and "On Hold" students are included in the totals for this
        report.{" "}
      </p>
    </div>
  );
}

export default FormulaNote;
