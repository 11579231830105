import React, { useState, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import useGlobal from "../../../../hooks/store";
const urlRegex = /(https?:\/\/[^\s]+)/g;
import { renderToString } from "react-dom/server";
import { formatShortDate } from "../utils";

const StudentCell = ({ row }) => {
  const [showModal, setShowModal] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [dataStudent, setDataStudent] = useState({});
  const tooltipRef = useRef(null);

  const fetchData = async () => {
    try {
      const response = await globalActions.dashboard.getDataStudent({
        student_id: row.original.student_id,
      });
      setDataStudent(response.data);
      ReactTooltip.rebuild();
    } catch (error) {
      console.log(error);
    }
  };

  const displayValue = (value) => {
    return value ? value : "-";
  };

  const displayMultiLineValue = (value) => {
    const maxLength = 20;
    const lines = [];
    while (value.length > maxLength) {
      lines.push(value.slice(0, maxLength));
      value = value.slice(maxLength);
    }
    lines.push(value);

    return lines.map((line, index) => <div key={index}>{line}</div>);
  };

  const formattedData = () => {
    if (dataStudent) {
      const tooltipContent = (
        <div>
          <div>
            <strong>Student Name:</strong>{" "}
            {displayValue(dataStudent.student_name)}
          </div>
          <div>
            <strong>High School Year of Graduation:</strong>{" "}
            {displayValue(dataStudent.high_school_year_of_graduation)}
          </div>
          <div>
            <strong>Gender:</strong> {displayValue(dataStudent.gender)}
          </div>
          <div>
            <strong>Final Major:</strong>{" "}
            {displayMultiLineValue(displayValue(dataStudent.major?.[0]))}
          </div>
          <div>
            <strong>Preliminary Major:</strong>{" "}
            {displayMultiLineValue(displayValue(dataStudent.major?.[1]))}
          </div>
          <div>
            <strong>High School:</strong>{" "}
            {displayValue(dataStudent.high_school)}
          </div>
          <div>
            <strong>State:</strong> {displayValue(dataStudent.state)}
          </div>
          <div>
            <strong>Enrollment Date:</strong>{" "}
            {displayValue(dataStudent.enrollment_date)}
          </div>
          <div>
            <strong>Services:</strong>
            <ul>
              {dataStudent.services?.map((service, index) => (
                <div key={index}>
                  <li>{service.service_name}</li>
                </div>
              ))}
            </ul>
          </div>
          <div>
            <strong>Experiences:</strong>
            <ul>
              {dataStudent.experiences?.map((experience, index) => (
                <div key={index}>
                  <li>{experience.consideration_name}</li>
                </div>
              ))}
            </ul>
          </div>
          <div>
            <strong>Team Members:</strong>
            <ul>
              {dataStudent.team_members?.map((team_member, index) => (
                <div key={index}>
                  <li>{team_member.team_member_name}</li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      );

      return renderToString(tooltipContent);
    }
  };

  const handleMouseEnter = () => {
    if (dataStudent.length != 0) {
      fetchData();
    }
    ReactTooltip.show(tooltipRef.current);
  };

  const handleMouseLeave = () => {
    ReactTooltip.hide(tooltipRef.current);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="student-cell">
      <a
        href={`/backend/students/${row.original.student_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {row.values.student_name}
      </a>

      <div ref={tooltipRef}>
        <ReactTooltip
          id={`tooltip-${row.original.student_id}`}
          place="left"
          effect="solid"
          html={true}
          class="text-left"
          getContent={() => formattedData()}
        />

        <div className="info-container">
          <img
            className="img-information"
            src={"/images/iconos_dashboard/information.png"}
            alt="Student Information"
            data-tip
            data-for={`tooltip-${row.original.student_id}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {row.original.on_hold && <p>On Hold</p>}
        </div>
      </div>
    </div>
  );
};

const generalInformation = () => [
  {
    Header: "STUDENTS",
    accessor: "student_name",
    Cell: ({ row }) => <StudentCell row={row} />,
  },
  {
    Header: "PROGRAMS",
    accessor: "program_names",
    Cell: ({ row }) => {
      const programNames = row.values.program_names
        ? row.values.program_names.split(", ")
        : [];
      return (
        <div>
          {programNames.length > 0 ? (
            programNames.map((program, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                <span>{program}</span>
              </React.Fragment>
            ))
          ) : (
            <span>-</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "NUMBER OF MEETINGS",
    accessor: "number_of_meetings",
    Cell: ({ row }) => row.values.number_of_meetings || 0,
  },
  {
    Header: "LAST MEETING",
    accessor: "last_meeting",
    Cell: ({ row }) => formatShortDate(row.values.last_meeting),
  },
  {
    Header: "NEXT CONTACT DATE",
    accessor: "next_contact_date",
    Cell: ({ row }) => formatShortDate(row.values.next_contact_date),
  },
  {
    Header: "NEXT MEETING",
    accessor: "next_meeting",
    Cell: ({ row }) => row.values.next_meeting || "-",
  },
  {
    Header: "MEETINGS USED / TOTAL MEETINGS",
    accessor: "counselor_meetings",
    Cell: ({ row }) =>
      row.original.counselor_used + " / " + row.original.counselor_total || "-",
  },
  {
    Header: "NUMBER OF WORKSHOPS",
    accessor: "number_of_workshops",
    Cell: ({ row }) => row.values.number_of_workshops || 0,
  },
  {
    Header: "NEXT BOOKED WORKSHOP",
    accessor: "next_booked_workshop_name",
    Cell: ({ row }) => row.values.next_booked_workshop_name || "NOT BOOKED",
  },
  {
    Header: "NEXT BOOKED WORKSHOP DATE",
    accessor: "next_booked_workshop_date",
    Cell: ({ row }) => {
      const value = row.values.next_booked_workshop_date;

      if (value) {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      } else {
        return "-";
      }
    },
  },
  {
    Header: "NOTES",
    accessor: "notes_dashboard",
    Cell: ({ value, row }) => {
      const isUrl =
        typeof value === "string" &&
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value);
      const overflow = isUrl
        ? isUrl
          ? "hidden"
          : "visible"
        : isUrl
        ? "visible"
        : "hidden";
      const maxWidth = isUrl ? "260px" : isUrl ? "180px" : "none";

      const urlMatch = typeof value === "string" ? value.match(urlRegex) : null;
      const displayValue = urlMatch ? urlMatch[0] : value;

      const formattedValue = (value) => {
        const words = value.split(" ");
        const divs = [];
        for (let i = 0; i < words.length; i += 10) {
          const chunk = words.slice(i, i + 10).join(" ");
          divs.push(`<div>${chunk}</div>`);
        }
        return divs.join("");
      };

      const shorterValue = (value) => {
        var truncatedValue = value?.substring(0, 100);
        if (truncatedValue.length < value.length) {
          const lastSpaceIndex = truncatedValue.lastIndexOf(" ");
          if (lastSpaceIndex !== -1) {
            truncatedValue = truncatedValue.substring(0, lastSpaceIndex + 1);
          }
        }
        return truncatedValue;
      };

      return (
        <div style={{ maxWidth, overflow, maxHeight: "20%" }}>
          {isUrl ? (
            <span style={{ color: "black", textDecoration: "none" }}>
              {displayValue}
            </span>
          ) : (
            <span>{value ? shorterValue(value) : ""}</span>
          )}
          {value?.length > 100 && (
            <span>
              <ReactTooltip
                id={`note-tooltip-${row.original.student_id}`}
                place="left"
                effect="solid"
                html={true}
                className="text-left"
                getContent={() => formattedValue(value)}
              />
              <span
                data-tip
                data-for={`note-tooltip-${row.original.student_id}`}
                class="label label-default"
              >
                . . .
              </span>
            </span>
          )}
        </div>
      );
    },
  },
];
export { generalInformation };
