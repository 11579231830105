import React, { useEffect, useState } from "react";
import EssayCoachPerformanceReportTable from "./EssayCoachPerformanceReportTable";
import { coachperformancecolumns } from "./table_columns/coach_performance_columns";
import { Filters } from "./Filters";
import useGlobal from "../../../hooks/store";
import "../../../../assets/stylesheets/backend/milestone_report/milestone_report.scss";
import FormulaNote from "./FormulaNote";

function EssayCoachPerformanceReport({ grad_year_list, program_list }) {
  const [loading, setLoading] = useState(true);
  const [dataReport, setDataReport] = useState([]);
  const [globalState, globalActions] = useGlobal();
  const { gradYear, programNames } = globalState.essayCoachPerformanceFilters;
  const { handleStoreChange } = globalActions.essayCoachPerformanceFilters;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterParams = filterDataParams({
          filter_by_grad_year: gradYear || "",
          filter_by_programs: programNames || [],
        });
        await filterData(filterParams, "");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [gradYear, programNames]);

  const filterDataParams = (commonFilters) => commonFilters;

  const filterData = async (options, filterType) => {
    setLoading(true);
    try {
      const response =
        await globalActions.essayCoachPerformanceFilters.filterDataBy({
          ...options,
        });
      setDataReport(response.data);
      handleStoreChange("filterType", filterType);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="report-title">Essay Coach Performance Report</h1>
      <Filters
        grad_year_list={grad_year_list}
        program_list={program_list}
        filterData={filterData}
      />
      <EssayCoachPerformanceReportTable
        coachperformancecolumns={coachperformancecolumns}
        data={dataReport}
        loading={loading}
      />
      <FormulaNote />
    </div>
  );
}

export default EssayCoachPerformanceReport;
